import React, { ReactNode } from 'react';

import './src/styles/global.scss';

import AppProvider from './src/context/AppProvider';
import { trackPageViewEvent } from './src/helpers/gtm';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onRouteUpdate = () => {
  setTimeout(trackPageViewEvent, 1000);
};

export const wrapRootElement = ({ element }: { element: ReactNode }) => {
  return <AppProvider>{element}</AppProvider>;
};
